body{
  overflow-x: hidden;
}
.page{
  padding: 60pt 0 150pt 0;
}
.content-wrap {
  min-height: 100vh;
  width: 100vw;
  .inner-content {
    position: relative;
    width: 100%;
    max-width: 100%;
    // &.inner-home {
    //   padding: 50pt 80pt;
    // }
    &.inner-options {
      color: #000;
      padding-top: 40pt;
      position: relative;
      min-height: 90vh;
      .next-btn{
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1000;
        text-align: center;
        &.ready{
          bottom: 45%;
        }
        img{
          width: 80pt;
          cursor: pointer;
          z-index: 10000;
          &:hover{
            filter: blur(0.5);
          }
        }
      }
    }
    &.inner-stat {
      color: #000;
      max-width: 100%;
    }
    .banner{
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      padding: 80pt;
      position: relative;
      .button{
        position: absolute;
        bottom: 10%;
        width: 100%;
        text-align: center;
        img{
          width: 80pt;
          cursor: pointer;
        }
      }
    }
    .banner-home{
      width: 60%;
      text-align: center;
      padding: 15pt 0;
      .logos{
        display: flex;
        justify-content: center;
        align-items: center;
        .logo-1{
          width: 110pt;
          margin: 30pt 20pt;
        }
        .logo-2{
          width: 60pt;
          margin: 30pt 10pt;
        }
      }
    }
    .title-home {
      margin: 20pt 0;
      h1 {
        font-weight: 700;
        font-size: 83pt;
        letter-spacing: 9pt;
        line-height: 80pt;
        span{
          font-size: 65pt;
          letter-spacing: 13pt;
          font-weight: 400;
          &.first{
            font-size: 90pt;
          }
        }
      }
      h2 {
        background: #000;
        color: white;
        font-weight: 500;
        font-size: 27pt;
        letter-spacing: 8pt;
        margin: 0 125pt;
      }
    }
    .logos-home {
      margin-bottom: 40pt;
      text-align: center;
      img {
        padding: 10pt 20pt;
        width: 90pt;
      }
    }
  }
}
.instructions-home {
  background-image: url('../public/images/bg1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  max-width: 100%;
  position: relative;
  padding-top: 50pt;
  padding-bottom: 50pt;
  .swiper{
    height: 40vw;
    margin: 0 1vw;
  }
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  .btn-login{
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: -5%;
    z-index: 100;
    //left: 50%;
    cursor: pointer;
    img{
      
      width: 80pt;
      z-index: 300;
    }
  }
  img{
    width: 100%;
  }
  .title-ins {
    font-weight: 500;
    font-size: 30pt;
    letter-spacing: 5pt;
    margin: 30pt 0;
  }
}
.box-login {
  background-image: url('../public/images/bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  min-height: 100vh;
  position: relative;
  .error {
    color: red;
    font-size: 10pt;
    margin-top: 10pt;
  }
  .inner-box {
    width: 500pt;
    margin: 0 auto;
    padding-top: 80pt;
    z-index: 1000;
    position: relative;
    .t {
      font-weight: 700;
      letter-spacing: 8pt;
      margin-bottom: 20pt;
      font-size: 48pt;
      span{
        font-weight: 400;
      }
    }
    .form {
      .label {
        font-size: 10pt;
        margin-bottom: 10pt;
      }
      input {
        border: 2px solid #FF4D1D;
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        //filter: drop-shadow(0px 3px 3px rgba(94, 94, 94, 0.25));
        border-radius: 30px;
        width: 100%;
        padding: 10pt;
        font-size: 15pt;
        margin-bottom: 15pt;
        &::placeholder{
          color: black;
        }
      }
      .link{
        margin: 10pt 0;
        color: #FF4D1D;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &.opened {
    //background: rgba(255, 255, 255, 0.9);
    //border-radius: 10pt;
    padding: 40pt 020pt;
    color: #000;
    //max-width: 500pt;
    margin: 0 auto;
    .btn {
      background: #FF4D1D;
      color: #fff;
      font-weight: 700;
      position: relative;
      z-index: 1000;
      &:hover {
        background: #fff;
        color: #FF4D1D;
      }
    }
  }
  .img-mountain{
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.contain-option {
  text-align: center;
  padding: 10pt;
  max-width: 1000pt;
  margin: 0 auto;
  .center{
    text-align: center;
    img{
      margin-top: 40pt;
      width: 90pt;
      cursor: pointer;
    }
  }
  &.contain-mountain{
    max-width: 90%;
  }
  .title-option {
    letter-spacing: 6pt;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 30pt;
    span{
      font-weight: 400;
    }
  }
  .content-option {
    width: 100%;
    position: relative;
  }
}
.slider {
  .swiper {padding: 10pt 30pt;}
  .swiper-button-next {
    right: 20%;
    color: #FF4D1D;
  }
  .swiper-button-prev {
    left: 20%;
    color: #FF4D1D;
  }
}
.item-option,.item-mountain {
 border-radius: 20pt 20pt 0px 0px;
 padding: 20pt;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .img {
    width: 100%;
    //border-radius: 20pt 20pt 0 0;
    position: relative;
    img {
      border-radius: 20pt 20pt 0 0;
      display: block;
    }
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4pt;
    justify-content: space-around;
    padding: 20pt 10pt 40pt 10pt;
    color: black;
    .col-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16pt;
      .icon {
        font-size: 20pt;
        margin-right: 4pt;
      }
    }
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.404);
  }
  &.selected {
    transform: scale(1.04);
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.6);
  }
}
.cards {
  display: flex;
  gap: 20pt;
  margin-top: 80pt;
  .card-level {
    border-radius: 20pt;
    img {
      border-radius: 20pt;
      display: block;
    }
    .title{ 
      border-radius: 20pt;
      color: #FFF;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      .name{
        font-weight: 500;
        font-size: 27pt;
      }
      .steps{
        font-size: 24pt;
        font-weight: 400;
      }
     }
    //box-shadow: 0px 4pt 4pt rgba(0, 0, 0, 0.25);
    width: 100%;
    position: relative;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.404);
      .title{
        background-color: rgba(0, 0, 0, 0);
      }
    }
    &.selected {
      transform: scale(1.1);
      box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.6);
      .title{
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
}
.avatars {
  display: flex;
  gap: 10pt;
  flex-wrap: wrap;
  .avatar {
    flex-grow: 1;
    position: relative;
    width: 14%;
    border-radius: 50%;
    .selected-icon{
      display: none;
      position: absolute;
      width: 30pt;
      right: 10pt;
      top: 10pt;
    }
    &:hover {
      transform: scale(1.02);
      background-color: #FF4D1D;
      border-radius: 50%;
    }
    &.selected-avatar {
      // background-image: url('../public/images/Ellipse-1.png');
      // background-size: cover;
      // background-repeat: no-repeat;
      background-color: #FF4D1D;
      transform: scale(1.05);
    }
  } 
}
.title-img {
  padding: 40pt 10pt;
  text-align: center;
  color: #FF4D1D;
  font-size: 20pt;
  font-weight: 700;
  letter-spacing: 4pt;
  text-transform: uppercase;
}
.selected {
  .title-img span {
    top: -10%;
  }
}
.btn {
  background: #FF4D1D;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 200pt;
  margin: 0 auto;
  color: #FFF;
  padding: 10pt 0;
  text-align: center;
  font-weight: 600;
  &:hover {
    background: #fff;
    color: #FF4D1D;
  }
}
.back {
  position: absolute;
  left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF4D1D;
  font-size: 16pt;
  cursor: pointer;
  img {margin-right: 10pt;width: 10pt;}
  z-index: 50;
}
.progress {
  width: 2pt;
  height: 100%;
  background: #8bbcd12c;
  position: fixed;
  top: 0;
  right: 2%;
  .progress-bar {
    width: 5pt;
    margin-left: -1pt;
    height: 0;
    background: #FF4D1D;
  }
}

.content-full {
  width: 100%;
  display: block;
  padding: 0;
  .inner-stat {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .header {
      //height: 50pt;
      //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.096);
      display: flex;
      align-items: center;
      justify-content: end;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      padding: 10pt;
      //background: white;
      z-index: 100;
      .header-left {
        padding: 0 20pt;
        img {
          width: 30pt;
        }
      }
      .header-right {
        display: flex;
        .header-right-item {
          text-align: center;
          .avatar{
            img{
              background-color: #FF4D1D;
              border-radius: 50%;
              width: 75pt;
            }
            .name{
              text-decoration: none;
            }
          }
          padding: 0 20pt;
          .logout {
            margin-top: 4pt;
            text-decoration: underline;
            color: #FF4D1D;
            font-size: 10pt;
          }
          &:hover {
            font-weight: 500;
          }
        }
      }
    }
    .menu{
      border: 3px solid #FF4D1D;
      width: 90pt;
      border-radius: 60pt;
      padding: 10pt;
      position: absolute;
      top: 15%;
      left: 5%;
      z-index: 300;
      .item{
        text-align: center;
        color: #FF4D1D;
        margin: 20pt 0;
        cursor: pointer;
        .icon-mountain{
          height: 23pt;
        }
        .icon-level, .icon-tracking{
          height: 27pt;
        }
        .icon-tracking1{
          height: 32pt;
        }
        .item-name{
          margin-top: 5pt;
          font-size: 10pt;
          .opt{
            font-weight: 700;
            font-size: 9pt;
          }
        }
      }
    }
    .content-stat {
      background: white;
      height: 100%;
      //padding-top: 50pt;
      .inner-content-stat {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-top: 70pt;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 30pt;
        &.team{
          display: flex;
          justify-content: end;
          padding-right: 5%;
        }
        .title-stat {
          font-size: 36pt;
          letter-spacing: 10pt;
          font-weight: 800;
          margin-bottom: 40pt;
          &.margin {
            margin-top: 60pt;
          }
        }
        .mountain-name{
          font-size: 30pt;
          color: #FF4D1D;
          font-weight: 800;
          letter-spacing: 8pt;
        }
        .info-steps{
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 50pt 0;
          .col{
            width: 20%;
            .label{
              font-size: 12pt;
              letter-spacing: 2.5pt;
            }
            .text{
              font-weight: 700;
              font-size: 12pt;
              }
            .icon-step{
              font-size: 34pt;
            }
          }
        }
        .hello-stat {
          font-size: 14pt;
          letter-spacing: 4pt;
          margin-bottom: 40pt;
        }
        .mountain-count {
          display: flex;
          justify-content: space-between;
          .mountain-count-item {
            width: 100pt;
            opacity: 0.3;
            .img {
              width: 50pt;
              margin: 0 auto;
            }
            .num {
              font-size:8pt;
              &.numt {
                font-size: 10pt;
              }
            }
            &.active {
              opacity: 1;
            }
          }
        }
        .mountain {
          display: flex;
          .item-mountain-box {
            width: 300pt;
          }
          .info-mountain {
            flex: 1;
            .row {
              display: flex;
              width: 100%;
              margin-bottom: 20pt;
              .col {
                .input {
                  width: 100%;
                  input {
                    width: 100%;
                    border: 2pt solid #FF4D1D;
                    filter: drop-shadow(0pt 3pt 3pt rgba(0, 0, 0, 0.25));
                    border-radius: 30pt;
                    text-align: center;
                    padding: 6pt 10pt;
                  }
                }
              }
              &.rowbottom {
                .col {
                  width: 50%;
                  margin: 0 auto;
                  padding: 0pt;
                  .input {
                    input {
                      outline: none;
                      background-color: #ffffff00;
                      width: 100%;
                      font-size: 15pt;
                      &::placeholder{
                        color: #000;
                      }
                    }
                  }
                }
              }
              .mountain-img{
                img{
                  width: 100%;
                }
              }
            }
          }
        }
        .container-graph{
          width: 80%;
        }
      }
      .tracking {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 600pt;
        width: 100%;
        margin: 0 auto 100pt auto;
        .col {
          width: 33.33%;
          &.col-graph {
            width: 250pt;
          }
        }
        .avatar {
          width: 100%;
          img {
            width: 60%;
          }
          .name {
            font-size: 14pt;
            letter-spacing: 4pt;
            margin-top: 10pt;
          }
        }
        .graph-circles {
          width: 100%;
          div{
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .graph-circle-1 {
            width: 200pt;
            height: 200pt;
            border: 15pt solid #FFB200;
            margin: 0 auto;
            .graph-circle-2 {
              width: 150pt;
              height: 150pt;
              border: 15pt solid #FF3A29;
              .graph-circle-3 {
                width: 100pt;
                height: 100pt;
                border: 15pt solid #4339F2;
              }
            }
          }
        }
        .row-legend {
          display: flex;
          margin-bottom: 10pt;
          .circle-legend {
            width: 10pt;
            height: 10pt;
            border-radius: 50%;
            background: #FFB200;
            margin-right: 10pt;
            &.onw{
              background: #FFB200;
            }
            &.two{
              background: #FF3A29;
            }
            &.three{
              background: #4339F2;
            }
          }
          .label {
            font-size: 10pt;
            letter-spacing: 2pt;
            text-align: left;
          }
        }
      }
    }
  }
}
.selectMenu {
  text-decoration: underline;
}
.graphs-box {
  
  .graph-container {
    width: 100%;
    min-height: 400pt;
  }
}
.header-graph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20pt;
  &.r {
    justify-content: flex-end;
  }
  &.l {
    justify-content: flex-start;
  }
  &.margin {
    margin-top: 20pt;
  }
  
}
.item-header-graph {
  width: 80pt;
  font-size: 10pt;
  text-align: center;
  .avatar {
    width: 40pt;
    margin: 0 auto;
  }
  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    .point {
      width: 5pt;
      height: 5pt;
      border-radius: 50%;
      margin-right: 4pt;
    }
  }
}
.filter-graph {
  display: flex;
  gap: 10pt;
  align-items: center;
  .filter-item {
    select {
      border: 0;
      background: #FFFFFF;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      padding: 4pt;
    }
  }
}

.graphs-cols {
  display: flex;
  .graph-col {
    width: 50%;
  }
}

.gauge-box {
  display: flex;
  justify-content: space-between;
  padding: 0 20pt;
  flex-wrap: wrap;
    gap: 20pt;
}

.graph-col-row {
  margin-bottom: 80pt;
}

table{
  width: 100%;
 tbody{
  .col{
    width: 15%;
  }
  .graphs{
    width: 40%;
    text-align: left;
    padding: 10pt 0;
    .item{
      display: flex;
      align-items: center;
      .name{
        width: 20%;
      }
      .item-graph{
        border-top-right-radius: 10pt;
        border-bottom-right-radius: 10pt;
        width: 80%;
        margin: 5pt 0;   
        color: #fff;
        text-align: center;
      }
      &.item:nth-child(1) .item-graph{
        background-color: #63A9CB;
      }
      &.item:nth-child(2) .item-graph{
        background-color: #4339F2;
      }
      &.item:nth-child(3) .item-graph{
        background-color: #FF3A29;
      }
      &.item:nth-child(4) .item-graph{
        background-color: #FFB200;
      }
      &.item:nth-child(5) .item-graph{
        background-color: #34B53A;
      } 
    }

  }
 }
}

.teams-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600pt !important;
  margin: 0 auto;
  .team-option {
    text-align: center;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    width: 100%;
    margin-bottom: 10pt;
    padding: 10pt;
    border-radius: 30pt;
    position: relative;
    font-size: 22pt;
    background-color: #8BBCD1;
    &:hover {
      background: #a3a3a3;
      .option-selected {
        background: #fff;
      }
    }
    .option-selected {
      margin-right: 10pt;
      position: relative;
      width: 10pt;
      height: 10pt;
      border-radius: 50%;
      background: #aaa;
    }
    &.selected-team {
      background: #FF4D1D;
      color: white;
     
    }
  }
}
.error {
  color: rgb(221, 68, 68);
  font-size: 12pt;
  margin-top: 10pt;
  width: 100%;
  text-align: center;
}
.add-mountain {
  margin-bottom: 40pt;
  .t-m {
    font-size: 14pt;
    letter-spacing: 2pt;
    margin-bottom: 10pt;
    color: #30b346;
    font-weight: bold;
  }
  .s-m {
    font-size: 10pt;
    letter-spacing: 2pt;
    margin-bottom: 10pt;
  }
}
.used {
  filter: grayscale(100%);
  pointer-events: none;
}

@media screen and (max-width: 1050pt) {
  .content-full .inner-stat{
    .header .header-right .header-right-item{
      .avatar{
        img{
          width: 50pt;
        }
      }
    }
    .menu{
      width: 70pt;
      left: 3%;
      .item{
        .icon-mountain{
          height: 20pt;
        }
        .icon-level, .icon-tracking{
          height: 24pt;
        }
        .icon-tracking1{
          height: 28pt;
        }
        .item-name{
          font-size: 9.5pt;
          .opt{
            font-size: 8.5pt;
          }
        }
      }
    }
  }
  .content-wrap {
    width: 100%;
    .inner-content {
      width: 100%;
      .banner{
        padding: 40pt 30pt;
        position: relative;
        .button{
          position: absolute;
          bottom: 7%;
          img{
            width: 70pt;
          }
        }
      }
      .banner-home{
        width: 60%;
        text-align: center;
        padding: 5pt 0;
        .logos{
          display: flex;
          justify-content: center;
          .logo-1{
            width: 80pt;
            margin: 10pt 20pt;
          }
          .logo-2{
            width: 50pt;
            margin: 10pt 10pt;
          }
        }
      }
      .title-home {
        margin: 20pt 0;
        h1 {
          font-size: 65pt;
          line-height: 60pt;
          span{
            font-size: 45pt;
            &.first{
              font-size: 70pt;
            }
          }
        }
        h2 {
          font-size: 20pt;
          margin: 0 70pt;
        }
      }
      .logos-home {
        img {
          padding: 10pt 20pt;
          width: 80pt;
        }
      }
      &.inner-options {
        min-height: 100vh;
        .next-btn{
          img{
            width: 70pt;
          }
        }
      }
    }
  }
  .instructions-home .btn-login{

    img{
      width: 70pt;
    }

  }
  .item-option .info .col-info, .item-mountain .info .col-info {
    font-size: 6pt;
  }
  // .title-img {
  //   font-size: 10pt;
  // }
  .avatars .avatar .selected-icon {
    width: 20pt;
  }
  .content-full .inner-stat .content-stat .inner-content-stat .mountain-count .mountain-count-item {
    width: 85pt;
  }
  .content-full .inner-stat .content-stat .inner-content-stat .mountain .info-mountain .row .col .label {
    font-size: 6pt;
    align-items: center;
  }
  .content-full .inner-stat .content-stat .inner-content-stat .mountain .info-mountain .row .col .text {
    font-size: 7pt;
  }
}

@media screen and (max-width: 768pt){
  .content-wrap {
    .inner-content {
      .title-home {
        h1 {
          font-size: 60pt;
          line-height: 50pt;
          span{
            font-size: 40pt;
            &.first{
              font-size: 60pt;
            }
          }
        }
        h2 {
          font-size: 17pt;
          margin: 0 20pt;
        }
      }
      .logos-home {
        img {
          padding: 10pt 20pt;
          width: 80pt;
        }
      }
    }
    .banner .button{
      left: 0;
    }
  }

}

@media screen and (max-width: 744pt){
  .content-wrap
    .inner-content {
      .title-home {
        h1 {
          font-size: 40pt;
          line-height: 40pt;
          margin-bottom: 15pt;
          span{
            font-size: 35pt;
            &.first{
              font-size: 43pt;
            }
          }
        }
        h2 {
          font-size: 15pt;
          margin: 0;
        }
      }
      .logos-home {
        img {
          padding: 10pt 20pt;
          width: 80pt;
        }
      }
    }
}

@media screen and (max-width: 767px) {
  .gauge-box {
    flex-wrap: wrap;
  }
  .content-wrap .inner-content .title-home {
    h1 {
      font-size: 40pt;
      margin-bottom: 10pt;
      span{
        font-size: 30pt;
        &.first{
          font-size: 40pt;
        }
      }
    }
    h2 {
      letter-spacing: 5pt;
    }
  }
  .content-wrap {
    .inner-content {
      .banner{
        height: 100vh;
        width: 100%;
        display: flex;
        max-width: initial;
        .banner-home {
          justify-content: center;
          display: flex;
          justify-items: center;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
        }
        .button{
          position: absolute;
          bottom: 4%;
          img {
            width:3rem;
          }
        }
      }
      .instructions-home {
        height: 100vh;
        width: 100%;
        display: grid;
      }
      .box-login {
        display: grid;
        .box-container {
          justify-content: center;
          display: flex;
          justify-items: center;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          }
      }
    }
  }
  .instructions-home{
    .swiper{
      height: 345pt;
    }
    .parallax-bg {
      width: 150%;
    }
  }
  .slider .swiper {
    padding: 10pt 5pt;
  }
  .back {
    top: 20pt;
  }
  .contain-option .content-option {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 70pt;
    .card-level {
      width: 45%;
      .title-img {
        font-size: 7pt;
      }
    }
  }
  .teams-box{
    width: 85% !important;
    margin: 0 auto;
  }
  .avatars .avatar {
    flex-grow: 0;
    position: relative;
    width: 28%; 
    .selected-icon {
      width: 15pt;
      right: 0;
      top: 0;
    }
  }
  .content-full .inner-stat .content-stat .inner-content-stat {
    padding: 10pt 20pt;
  }
  .content-full .inner-stat .content-stat .inner-content-stat .mountain-count {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10pt;
  }
  .content-full .inner-stat .content-stat {
    .inner-content-stat {
      .mountain {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10pt;
      }
      .info-mountain {
        padding: 10pt 0 !important;
        .row {
          flex-direction: column;
          .col {
            margin-bottom: 10pt;
            width: 100% !important;
            .label {
              font-size: 10pt !important;
            }
            .text {
              font-size: 10pt !important;
            }
          }
        }
      }
    }
    .tracking {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .col {
        width: 100%;
        margin-bottom: 20pt;
      }
      .row-legend {
        justify-content: center;
        .label {
          text-align: center;
          font-size: 14pt;
        }
      }
    }
  }
  .header {
    .header-right {
      font-size: 8pt !important;
    }
  }
  .content-full .inner-stat .content-stat .inner-content-stat {
    padding: 10pt 0pt;
  } 
  .filter-graph {
    justify-content: center;
    margin-bottom: 20pt;
  }
  .header-graph {
    justify-content: center;
    &.r, &.l {
      justify-content: center;
      flex-wrap: wrap;
      .item-header-graph {
        margin: 10pt;
      }
    }
  }
  .graphs-cols {
    flex-direction: column;
    .graph-col {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .content-wrap {
    .inner-content {
      .banner{
        height: 100vh;
        .banner-home {
          padding-top: inherit;
          .title-home {
            margin: 0.5rem 0 ;
          }
        }
        .button{
          bottom: 0;
          img {
            width:3rem;
          }
        }
      }
      .box-login {
        display: grid;
        .box-container {
          justify-content: center;
          display: flex;
          justify-items: center;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          }
      }
    }
  }

  .content-wrap .inner-content .title-home {
    h1 {
      font-size: 2rem;
      margin-bottom: 10pt;
      line-height: 2.5rem;
      span{
        font-size: 30pt;
        &.first{
          font-size: 2rem;
        }
      }
    }
    h2 {
      letter-spacing: 0.3rem;
    }
  }
}
@media screen and (max-width: 896px) and (orientation: landscape) {
  .box-login {
    display: grid;
    .box-container {
      display: contents;
    }
    .inner-box {
      padding-top:initial;
    }
  }
  .contain-option {
    width: 100%;
    .content-option {
      margin: auto;
      padding: 0.5rem;
    }
    .title-option {
      margin: 1rem auto;
    }
  }

  .slider .swiper {
      padding: 0.5rem;
      .item-option {
        padding: inherit;
        .title-img {
          padding: 1rem;
        }
        .info {
          padding: 1rem;
        }
      }
  }
  .content-wrap {
    .inner-content.inner-options {
      .next-btn.ready {
        bottom: 7%;
      }
    }
  }
}

@media screen and (max-width: 820px) and (orientation: portrait) {
  .contain-option {
    margin: 1rem auto;
    .content-option {
      margin: 3rem auto;
      padding: 0.5rem;
      .card-level {
        .title {
          .name {
            font-size: 1.8rem;
          }
        }
      }
    }
    .title-option {
      margin: 1rem auto;
    }
  }

  .content-wrap {
    .inner-content.inner-options {
      .next-btn.ready {
        bottom: 9%;
      }
    }
  }
}

@media screen and (max-width: 405pt) {
  .content-wrap {
    .inner-content {
      .banner .button{
        bottom: 5%;
        img {
            width:3rem;
          }
      }
      .banner-home{
        width: 100%;
        .logos{
          .logo-1{
            width: 65pt;
          }
          .logo-2{
            width: 40pt;
          }
        }
      }
      .title-home {
        margin: 20pt 0;
        h1 {
          font-size: 32pt;
          letter-spacing: 5pt;
          line-height: 30pt;
          span{
            font-size: 25pt;
            letter-spacing: 6pt;
            &.first{
              font-size: 35pt;
            }
          }
        }
        h2{
          font-size: 16pt;
          margin: 0;
          letter-spacing: 2pt;
        }
      }
      .logos-home{
        margin-top: 60pt;
        img{
          width: 70pt;
        }
      }
    }
  }
  .instructions-home{
    .title-ins{
      font-size: 22pt;
    }
    .swiper{
      height: 180pt;
    }
    .parallax-bg {
      width: 160%;
    }
  }
  .box-login .inner-box{
    width: 90%;
    margin: 0 auto;
    .t{
      font-size: 32pt;
    }
    .form{
      input{
        font-size: 10pt;
      }
    }
  }
  .teams-box {
    width: 95% !important;
    .team-option{
      font-size: 13pt;
    }
  }
  .contain-option{ 
    .title-option{
    font-size: 20pt;
    }
    .card-level{
      .title{
        .name{
          font-size: 20pt;
        }
        .steps{
          font-size: 16pt;
        }
      }
    }
    &.contain-mountain{
      max-width: 100%;
    }
  }
  .content-option{
    &.slider{
      padding-bottom: 0;
      .title-img{
        padding: 12pt;
      }
      .swiper-button-next{
        right: 10%;
      }
      .swiper-button-prev{
        left: 10%;
      }
    .item-option{
      padding: 0;
      .info{
        padding: 40pt 10pt;
      }
    }
    }
  }
  .content-wrap .inner-content.inner-options .next-btn.ready{
    bottom: 18%;
  }
  .content-full .inner-stat {
    .header .header-right .header-right-item {
      .logout{
        margin-top: 0;
      }
      .avatar img{
        width: 40pt;
      } 
    }
    .content-stat{
      .inner-content-stat{
        padding: 70pt 0 10pt 0;
        .info-steps{
          justify-content: space-around;
          .col{
            .label{
              letter-spacing: 1pt;
              font-size: 10pt;
            }
          }
        }
      }
    }
    .menu{
      width: 100%;
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      left: 0;
      bottom: 1%;
      top: 90%;
      z-index: 1000;
      .item{
        margin: 0 auto;
        .icon-mountain{
          height: 15pt;
        }
        .icon-level, .icon-tracking{
          height: 18pt;
        }
        .icon-tracking1{
          height: 22pt;
        }
        .item-name{
          font-size: 7pt;
        }
      }
    }
  }
}