.loader1 {
    border: 5pt solid #f3f3f3;
    border-top: 5pt solid #3498db;
    border-radius: 50%;
    width: 20pt;
    height: 20pt;
    animation: spin 2s linear infinite;
    margin: auto;
}

.loader2 {
    position: relative;
    width: 20pt;
    height: 20pt;
    margin: auto;
    transform: translateZ(0);
  }

.circle {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4pt solid transparent;
    border-top-color: #3498db;
  }
  
  .circle1 {
    animation: spin 1s linear infinite;
  }
  
  .circle2 {
    animation: spin 2s linear infinite;
    opacity: 0.7;
  }
  
  .circle3 {
    animation: spin 3s linear infinite;
    opacity: 0.4;
  }
  
  .circle4 {
    animation: spin 4s linear infinite;
    opacity: 0.1;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}