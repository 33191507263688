@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;900&display=swap');
@import url('./scss/animated.css');

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  color: #161616;
  background: white;
}

* {
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  -ms-overflow-style: none;
  box-sizing: border-box;
}

body,
html,
img,
video,
audio,
input,
select {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
  line-height: normal;
  cursor: pointer;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.transition {
  transition: all 0.5s ease-in-out;
}

.transition1 {
  transition: all 1s ease-in-out;
}